import request from '@/utils/request'

// 查询报名列表列表
export function listSignUp(query) {
  return request({
    url: '/bm/signUp/list',
    method: 'get',
    params: query
  })
}
// 付款成功
export function payment(query) {
  return request({
    url: '/bm/signUp/payment',
    method: 'get',
    params: query
  })
}
// 查询报名列表详细
export function getSignUp(id) {
  return request({
    url: '/bm/signUp/' + id,
    method: 'get'
  })
}

// 新增报名列表
export function addSignUp(data) {
  return request({
    url: '/bm/signUp/sign',
    method: 'post',
    data: data
  })
}

// 修改报名列表
export function updateSignUp(data) {
  return request({
    url: '/bm/signUp',
    method: 'put',
    data: data
  })
}

// 修改报名状态
export function status(data) {
  return request({
    url: '/bm/signUp',
    method: 'put',
    data: data
  })
}

// 删除报名列表
export function delSignUp(id) {
  return request({
    url: '/bm/signUp/' + id,
    method: 'delete'
  })
}

// 导出报名列表
export function exportSignUp(query) {
  return request({
    url: '/bm/signUp/export',
    method: 'get',
    params: query
  })
}
