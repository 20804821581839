<template>
  <div>
    <div class="collapse-wrapper">
      <div class="chapter-wrapper" v-for="(item,index) in courseTree" :key="index" ref="chapter">
        <div class="content-wrapper">
          <catalogue class="chapter-item" :catalogue="item" :is-join="isJoin" :needJoin="needJoin"
                     :open="item.open"
                     @handleOpen="handleOpen(item,index)" @handleCatalogue="handleCatalogue"></catalogue>
          <div v-for="(second,index) in item.children" :key="index">
            <catalogue :catalogue="second" :is-join="isJoin" @handleCatalogue="handleCatalogue" :needJoin="needJoin"></catalogue>
            <div v-for="(third,index) in second.children" :key="index">
              <catalogue :catalogue="third" :is-join="isJoin" @handleCatalogue="handleCatalogue" :needJoin="needJoin"></catalogue>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import catalogue from './catalogue/index.vue'

export default {
  components: {catalogue},
  props: {
    courseTree: {
      type: Array,
      default: () => []
    },
    isJoin: {
      type: String,
      default: ''
    },
    needJoin: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  methods: {
    handleOpen(item, index) {
      const chapter = this.$refs.chapter[index]
      if (item.open) {
        chapter.style.height = item.height + 'px'
        item.open = false
        this.$emit('handleOpen', item, index)
      } else {
        if (item.open === undefined) item.height = chapter.clientHeight
        item.open = true
        this.$emit('handleOpen', item, index)
        chapter.style.height = 56 + 'px'
      }
    },
    jumpRoute(period) {
      this.$emit('jumpRoute', period)
    },
    handleCatalogue(catalogue){
      const {tryWatch, periodType} = catalogue
      if (periodType === '2' && tryWatch !== 0) {
        this.$emit('handleTryOnSeeing', catalogue)
      } else {
        this.$notify({
          title: '提示',
          message: '此类为付费课程，请报名后继续学习！',
          type: 'warning',
          duration: 3000
        });
      }
    }
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.collapse-wrapper {
  padding: 24px;
  background: #FFFFFF;

  .chapter-wrapper {
    transition: all .3s ease;
    overflow: hidden;
  }
}
</style>
