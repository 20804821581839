<template>
  <div>
    <div class="container">
      <div class="idx-content">
        <!-- 面包屑导航 -->
        <div class="class-nav">
          当前位置：
          <router-link tag="a" to="/">首页</router-link>
          >
          {{ title }}
        </div>
        <!-- 企业定制化培训 -->
        <div class="class-box-1">
          <div class="class-box-1L">
            <div class="class-box-1L-1">
              <span class="title-box"> {{ title }}</span>
              <!-- 价格 -->
              <span class="price">￥{{ unitPrice }}</span>
              <!-- @mouseover.native='hoverQrImg' v-show="rolesShow" -->
              <el-popover
                placement="top-start"
                title=""
                width="255"
                trigger="click"
                @show="showClick"
                :disabled="clickDisabled"
                style="text-align: center"
                :close-delay="6000"
              >
                <div style="text-align: center" id="picture">
                  <div ref="bill" style="width: 230px; height: 290px">
                    <p style="font-size: 18px">请使用手机扫一扫</p>
                    <p style="font-size: 16px" v-show="myJobNumber !== null">
                      CQC{{ myJobNumber }}
                    </p>
                    <vue-qr
                      v-if="InvitationCode"
                      :logoSrc="qrimageUrl"
                      :logoScale="30"
                      :text="InvitationCode"
                      :size="200"
                    ></vue-qr>
                    <p style="font-size: 16px">中国质量认证中心</p>
                  </div>
                  <div>
                    <el-button size="mini" @click="savePic"
                      >下载邀请码</el-button
                    >
                    <el-button size="mini" @click="copyCourseAddress"
                      >复制课程链接
                    </el-button>
                  </div>
                </div>
                <span class="share" slot="reference">分享</span>
              </el-popover>
            </div>
            <div class="class-box-1L-2">
              <div style="width: 100%; height: auto; overflow: hidden">
                <div style="float: left; width: 50%; overflow: hidden">
                  <div class="class-box-1L-2-1">
                    <img src="@/assets/image/class-1.png" alt="" />
                    <!-- <span>报名人数：{{ totalSign }}/{{ maxSign }}</span> -->
                    <span
                      >报名人数：{{ totalSign }}/
                      <span v-if="maxSign <= 0">无限制人数</span>
                      <span v-else>{{ maxSign }}</span>
                    </span>
                  </div>
                  <div
                    v-if="courseType === '2'"
                    class="class-box-1L-2-1 class-box-1L-2-2"
                  >
                    <img src="@/assets/image/class-2.png" alt="" />
                    <span>报名截止日期：{{ signAsOfTime }}</span>
                  </div>
                </div>
                <div
                  v-if="courseType === '2'"
                  style="float: left; width: 50%; overflow: hidden"
                >
                  <div class="class-box-1L-2-1 class-box-1L-2-2">
                    <img src="@/assets/image/class-2.png" alt="" />
                    <span>培训开始日期：{{ courseStartTime }}</span>
                  </div>
                  <div class="class-box-1L-2-1 class-box-1L-2-2">
                    <img src="@/assets/image/class-2.png" alt="" />
                    <span>培训结束日期：{{ courseEndTime }}</span>
                  </div>
                </div>
              </div>
              <div class="class-box-1L-2-1 class-box-1L-2-3">
                <img src="@/assets/image/class-3.png" alt="" />
                <!-- <div class="way-pos type-val-xs0" v-if="item.way == 0">线上课程</div>
                <div class="way-pos type-val2-xs1" v-else>线下课程</div>
                <span>培训类型：{{ way | way }}</span> -->
                <span v-if="way == 0">培训类型：线上课程</span>
                <span v-else>培训类型：线下课程</span>
              </div>

              <!-- <div
                v-if="token == false || signId == null"
                class="class-box-1L-2-4"
                @click="dialogVisiblebtn()"
              >
                我要报名
              </div> -->
              <div
                v-if="token == false || signId == null || signId == null"
                class="class-box-1L-2-4"
                @click="dialogVisiblebtn()"
              >
                我要报名
              </div>
              <div v-if="signId > 0">
                <!-- <router-link
                  :to="{ name: 'signProgress', query: { id: courseId2 } }"
                > -->
                <div v-if="unitPrice > 0">
                  <router-link
                    v-if="affirmTime == null && status == 0"
                    :to="{ name: 'signProgress', query: { id: courseId2 } }"
                  >
                    <div class="class-box-1L-2-4">上传转账凭证</div>
                  </router-link>
                  <!-- 去上课 status: "2"-->
                  <!-- <div
                    class="class-box-1L-2-4"
                    v-show="affirmTime != null && way == 0 && status == 1"
                    @click="cqcLogin()"
                  > -->
                  <div
                    :class="[
                      affirmTime != null && way == 0
                        ? 'download_btn class-box-1L-2-4'
                        : 'class-box-1L-2-4',
                    ]"
                    v-show="affirmTime != null && way == 0"
                    @click="download_course"
                  >
                    下载课件
                  </div>
                  <div
                    class="class-box-1L-2-4"
                    v-show="affirmTime != null && way == 0"
                    @click="cqcLogin()"
                  >
                    去上课
                  </div>
                </div>
                <div v-else>
                  <!-- 去上课 -->
                  <!-- <div
                    class="class-box-1L-2-4"
                    v-show="affirmTime != null && way == 0 && status == 1"
                    @click="cqcLogin()"
                  > -->
                  <div
                    :class="[
                      affirmTime != null && way == 0
                        ? 'download_btn class-box-1L-2-4'
                        : 'class-box-1L-2-4',
                    ]"
                    v-show="affirmTime != null && way == 0"
                    @click="download_course"
                  >
                    下载课件
                  </div>
                  <div
                    class="class-box-1L-2-4"
                    v-show="affirmTime != null && way == 0"
                    @click="cqcLogin()"
                  >
                    去上课
                  </div>
                </div>
              </div>

              <!-- 我要报名 -->
              <el-dialog
                title=""
                :visible.sync="dialogVisible"
                width="55%"
                :append-to-body="true"
                :before-close="handleClose"
                :close-on-click-modal="false"
              >
                <el-form
                  ref="formMess"
                  :model="formMess"
                  label-width="20%"
                  :rules="formRules"
                  @submit.native.prevent
                >
                  <div class="classDetail-box">
                    <div class="classDetail-box2">
                      <div class="classDetail-box-1">
                        <div class="classDetail-box-1L">
                          <!-- // name unit idCode phone sex email address invoiceUp -->
                          <el-form-item label="姓名" prop="name">
                            <el-input
                              v-model="formMess.name"
                              class="my-R-1R my-name"
                              placeholder="请输入姓名"
                              clearable
                            ></el-input>
                          </el-form-item>
                          <el-form-item label="单位" prop="unit">
                            <el-input
                              v-model="formMess.unit"
                              class="my-R-1R my-name"
                              placeholder="请输入单位"
                              @blur="unitBlur"
                              clearable
                            ></el-input>
                          </el-form-item>
                          <el-form-item label="证件" prop="codeType">
                            <el-select
                              v-model="formMess.codeType"
                              placeholder="请选择证件类型"
                              class="my-R-1R my-name"
                              @change="codeTypeChange"
                            >
                              <el-option
                                v-for="item in certificateTypeOptions"
                                :key="item.dictValue"
                                :label="item.dictLabel"
                                :value="item.dictValue"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="证件号" prop="idCode">
                            <el-input
                              v-model="formMess.idCode"
                              class="my-R-1R my-name"
                              placeholder="请输入证件号"
                              clearable
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="classDetail-box-1L classDetail-box-1R">
                          <el-form-item label="手机号" prop="phone">
                            <el-input
                              v-model="formMess.phone"
                              class="my-R-1R my-name"
                              placeholder="请输入手机号"
                              clearable
                            ></el-input>
                          </el-form-item>
                          <!-- 性别 邮箱 地址 发票抬头 -->
                          <el-form-item label="性别" prop="sex">
                            <el-radio v-model="formMess.sex" label="0"
                              >男</el-radio
                            >
                            <el-radio v-model="formMess.sex" label="1"
                              >女</el-radio
                            >
                            <!-- <el-input
                              v-model="formMess.sex"
                              class="my-R-1R my-name"
                              placeholder="请输入性别"
                              clearable
                            ></el-input> -->
                          </el-form-item>
                          <!-- 判断课程是否免费 -->

                          <div v-if="unitPrice > 0">
                            <el-form-item label="邮箱" prop="email">
                              <el-input
                                v-model="formMess.email"
                                class="my-R-1R my-name"
                                placeholder="请输入邮箱"
                                clearable
                              ></el-input>
                            </el-form-item>
                            <el-form-item label="地址" prop="address">
                              <el-input
                                v-model="formMess.address"
                                class="my-R-1R my-name"
                                placeholder="请输入地址"
                                clearable
                              ></el-input>
                            </el-form-item>
                            <el-form-item label="发票抬头" prop="invoiceUp">
                              <el-input
                                v-model.trim="formMess.invoiceUp"
                                class="my-R-1R my-name"
                                placeholder="请输入发票抬头(公司名称)"
                                clearable
                              ></el-input>
                            </el-form-item>
                          </div>
                          <div v-else>
                            <el-form-item label="邮箱">
                              <el-input
                                v-model="formMess.email"
                                class="my-R-1R my-name"
                                placeholder="请输入邮箱"
                                clearable
                              ></el-input>
                            </el-form-item>
                            <el-form-item label="地址">
                              <el-input
                                v-model="formMess.address"
                                class="my-R-1R my-name"
                                placeholder="请输入地址"
                                clearable
                              ></el-input>
                            </el-form-item>
                            <!-- <el-form-item label="发票抬头">
                              <el-input
                                v-model="formMess.invoiceUp"
                                class="my-R-1R my-name"
                                placeholder="请输入发票抬头(公司名称)"
                                clearable
                              ></el-input>
                            </el-form-item> -->
                          </div>
                        </div>
                      </div>
                      <!-- *获知培训招生信息渠道： *获知培训招生信息渠道选“A”：请选择推荐人代码 -->
                      <div class="classDetail-box-2">
                        <el-form-item label="获知培训招生信息渠道">
                          <el-select
                            v-model="formMess.source"
                            placeholder="请选择"
                            @change="select_click"
                            :disabled="isOpen_select"
                          >
                            <el-option
                              v-for="(item, index) in formMess.option1"
                              :key="index"
                              :label="item.text"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          label="推荐人代码"
                          label-width="20%"
                          v-if="select_B"
                          prop="referees2"
                          :rules="[
                            { required: true, message: '推荐人代码不能为空' },
                            {
                              pattern: /^\d{1,}$/,
                              message: '请输入正确的格式',
                            },
                          ]"
                        >
                          <el-input
                            v-model="formMess.referees2"
                            class="my-R-1R my-name my-name1 tjInput"
                            style="width: 212px; position: relative"
                            placeholder=""
                            clearable
                            :disabled="inputdisabled"
                          >
                          </el-input>
                          <span
                            class="message"
                            style="position: absolute; left: 15px; color: #c0c4cc"
                            >CQC</span
                          >
                          <!-- <el-select v-model="formMess.referees" placeholder="请选择">
                            <el-option
                              v-for="(item, index) in formMess.option2"
                              :key="index"
                              :label="item.text"
                              :value="item.value"
                            ></el-option>
                          </el-select> -->
                        </el-form-item>

                        <el-form-item
                          label="推荐人代码"
                          label-width="20%"
                          v-if="select_A"
                          prop="referees1"
                          :rules="[
                            { required: true, message: '推荐人代码不能为空' },
                          ]"
                        >
                          <el-select
                            v-model="formMess.referees1"
                            placeholder="请选择推荐人员"
                            clearable
                          >
                            <el-option
                              v-for="(item, index) in formMess.option2"
                              :key="index"
                              :label="item.text"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <!-- close -->
                      <img
                        class="class-close"
                        src="@/assets/image/close.png"
                        alt=""
                      />
                      <!-- 填写报名信息 -->
                      <div class="class-top">填写报名信息</div>
                      <!-- 提交 -->
                      <!-- <div class="class-btn" @click="dialogVisible2 = true"> -->
                      <!-- <div class="class-btn" @click="submitScheduleForm">提交</div> -->
                      <el-button
                        class="class-btn2"
                        :type="disabled ? 'disabled' : 'primary'"
                        @click="submitScheduleForm"
                        :disabled="disabled"
                        >提交
                      </el-button>
                      <!-- <el-form-item>
                        <el-button type="primary" @click="onSubmit"
                          >立即创建</el-button
                        >
                        <el-button>取消</el-button>
                      </el-form-item> -->
                    </div>
                  </div>
                </el-form>
              </el-dialog>
              <!-- 报名成功 -->
              <el-dialog
                title=""
                :visible.sync="dialogVisible2"
                width="50%"
                :append-to-body="true"
                :before-close="handleClose"
                :close-on-click-modal="false"
              >
                <!--  -->
                <div class="class-sucess">
                  <div class="class-sucessbox">
                    <div class="class-sucess-1">
                      报名成功！({{ phone }} {{ name }})
                    </div>
                    <div class="class-sucess-2">
                      请将本页面截屏发给财务，方便转账及上传转账凭证
                    </div>
                    <div class="class-sucess-3">
                      <div class="class-sucess-3-1">{{ title }}</div>
                      <div class="class-sucess-3-2">
                        <span>需支付报名费用</span>
                        <span class="class-sucess-3-2b">￥{{ unitPrice }}</span>
                      </div>

                      <div class="class-sucess-3-3">
                        请将培训费用汇入以下银行账号:
                      </div>
                      <div class="class-sucess-3-3 class-sucess-3-4">
                        <span class="class-suc-s1">银行账号：</span>
                        <span>11000987403701</span>
                      </div>
                      <div class="class-sucess-3-3 class-sucess-3-5">
                        <span class="class-suc-s1">开户名称：</span>
                        <span>中国质量认证中心广州分中心</span>
                      </div>
                      <div class="class-sucess-3-3 class-sucess-3-6">
                        <span class="class-suc-s1">开户银行：</span>
                        <span>平安银行广州分行营业部</span>
                      </div>
                      <div class="class-sucess-3-3 class-sucess-3-7">
                        汇款请备注“培训费”
                      </div>
                      <div class="class-sucess-3-8">
                        重要提醒：依照国家税务相关文件规定，请报名学员以公对公转账方式进行缴费，方能开具对公发票，若以私人账户对公转账，仅能开具私人抬头发票，为保障学员有效报销，请严格遵循此原则缴纳培训费，发票一经开出，概不负责退换票。
                      </div>
                    </div>
                    <!-- 二维码 -->
                    <div class="class-sucess-4">
                      <div class="class-sucess-4L">
                        <!-- 扫二维码 -->
                        <vue-qr
                          :text="downloadData.url"
                          :margin="0"
                          colorDark="#000000"
                          colorLight="#fff"
                          :logoScale="0.3"
                          :size="200"
                        ></vue-qr>
                      </div>
                      <div class="class-sucess-4R">
                        转账成功后，请扫此二维码进入手机端上传付款凭证
                        或点击下方【确认】按钮上传
                      </div>
                    </div>
                    <!-- close -->
                    <img
                      class="class-close"
                      src="@/assets/image/close.png"
                      alt=""
                    />
                    <!-- 填写报名信息 -->
                    <div class="class-top">报名成功</div>
                    <!-- 确认 -->
                    <div class="suc-btn" @click="submitForm1()">确认</div>
                  </div>
                </div>
              </el-dialog>
              <!-- 文件下载 -->
              <el-dialog
                title=""
                :visible.sync="dialogVisible3"
                width="500px"
                :append-to-body="true"
                :before-close="handleClose"
                :close-on-click-modal="false"
                @close="close_page"
              >
                <div class="classDetail-box classDetail-box-new">
                  <div class="classDetail-box2 classDetail-box2-new">
                    <div v-if="download_courseList.length !== 0">
                      <el-checkbox-group
                        v-model="download_courseIds"
                        class="download-list"
                        v-loading="download_load"
                        @change="handleCheckedCitiesChange"
                      >
                        <li
                          v-for="(item, index) in download_courseList"
                          :key="index"
                        >
                          <el-checkbox
                            :label="item.name + '.' + item.suffix"
                            :value="item.id"
                            name="type"
                          ></el-checkbox>
                          <!-- <span>.doc</span> -->
                        </li>
                      </el-checkbox-group>
                      <div class="select-amount">
                        已选
                        <span class="red">{{ download_courseIds.length }}</span>
                        个课件
                      </div>
                      <div style="height: 45px; line-height: 45px">
                        <el-checkbox
                          :indeterminate="isIndeterminate"
                          v-model="checkAll"
                          @change="handleCheckAllChange"
                          >全选
                        </el-checkbox>
                        <el-button
                          class="class-btn2 class-btn3 class-btn4"
                          :type="disabled ? 'disabled' : 'primary'"
                          @click="submitDownload"
                          :disabled="disabled"
                          >下载
                        </el-button>
                      </div>
                    </div>
                    <div class="msg" v-else>暂时没有资料下载</div>
                    <!-- close -->
                    <img
                      class="class-close class-close-new"
                      src="@/assets/image/close.png"
                      alt=""
                    />
                    <!-- 填写报名信息 -->
                    <div class="class-top">资料下载</div>
                  </div>
                </div>
              </el-dialog>
            </div>
          </div>
          <div class="class-box-1R">
            <div class="class-box-1R-1">授课教师</div>
            <div class="class-box-1R-2">
              <div class="class-box-1R-2t">
                <img
                  v-if="signTeacher.sex == '1'"
                  src="@/assets/image/tx-girls.png"
                  alt=""
                />
                <img v-else src="@/assets/image/tx-boy.png" alt="" />
              </div>
              <div class="class-box-1R-2b">
                <span class="class-bs-1">{{ signTeacher.name }}</span>
                <span class="class-bs-2"></span>
                <span class="class-bs-3">教师</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 培训介绍 -->
        <div class="class-box-1 class-box-2">
          <div class="class-box-1L">
            <el-tabs v-if="courseType === '1'" v-model="activeNav">
              <el-tab-pane label="课程介绍" name="introduce"></el-tab-pane>
              <el-tab-pane label="课程目录" name="catalog"></el-tab-pane>
            </el-tabs>
            <div class="class-box-1L-1" v-if="courseType === '2'">培训介绍</div>
            <div
              class="class-box-1L-2box"
              v-if="activeNav === 'introduce'"
              v-html="content"
            >
              {{ content }}
            </div>
            <platformCourseMulu
              v-if="activeNav === 'catalog'"
              :courseTree="courseTree"
              @handleOpen="handleOpen"
              @handleTryOnSeeing="handleTryOnSeeing"
            ></platformCourseMulu>
          </div>
          <div class="class-box-1R">
            <div class="class-box-1R-1">
              最新学员（总报名{{ signStudent.length }}人）
            </div>
            <div class="class-box-1R-2">
              <div
                class="class-box-1R-2b"
                v-for="item in signStudent.slice(0, 15)"
                :key="item.id"
              >
                <!-- <img class="tx-img2" :src="item.evaluation" alt /> -->
                <!-- <img class="tx-img2" v-if="item.evaluation" :src="item.evaluation" alt /> -->
                <img
                  class="tx-img2"
                  v-if="item.sex == '1'"
                  src="@/assets/icon/xueyuan2.png"
                  alt=""
                />
                <img
                  class="tx-img2"
                  v-else
                  src="@/assets/icon/xueyuan1.png"
                  alt=""
                />
                <!-- <img class="tx-img2" src="@/assets/image/profile.jpg" alt="" />
                <img class="tx-img2" src="@/assets/image/profile.jpg" alt="" /> -->
                <!-- <img src="@/assets/image/profile.jpg" alt="" /> -->
                <span class="class-bs-2a">{{ item.name | ellipsis }}</span>
              </div>
              <!-- <div class="class-box-1R-2b">
                <img src="@/assets/image/profile.jpg" alt="" />
                <span class="class-bs-2a">小荔枝</span>
              </div>
              <div class="class-box-1R-2b">
                <img src="@/assets/image/profile.jpg" alt="" />
                <span class="class-bs-2a">小荔枝</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="tip-dialog"
      title="重要提醒"
      :visible.sync="tipVisible"
      width="420px"
    >
      <p style="margin-bottom: 4px;line-height: 18px">
        1、为避免账号无法正常上课，请勿代替他人报名，平台登录账号须与报名账号一致
      </p>
      <p style="line-height: 18px">
        2、本课程为虚拟课程，购买后将无法退款，请您理解，本次解释权归平台运营方所有，如有疑问请联系020-85190108。
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="tipVisible = false">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          :icon="confirmButtonDisabled ? 'el-icon-loading' : ''"
          :disabled="confirmButtonDisabled"
          @click="handleTipClose"
          >{{ confirmButtonText }}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      class="try-on-seeing-dialog"
      :title="videoFile.name"
      :visible.sync="Topen"
      width="680px"
      append-to-body
    >
      <components
        v-if="videoType"
        :is="videoType"
        :videoFile="videoFile"
        @handleApply="handleApply"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth';
import { formatDate } from '@/utils/date';
import { isWeiXin } from '@/utils/baike';
import {
  getCourseJoinUser,
  getCoursepc,
  getDownloadList,
} from '@/api/bm/course';

//
import { addSignUp } from '@/api/bm/signUp';
import { getStudent, signStudent, signTeacher } from '@/api/bm/student';
import { listCoursepayment } from '@/api/bm/upload';
import { getCoursePeriodTree } from '@/api/course/course';
import platformCourseMulu from './components/platformCourseMulu.vue';

import html2canvas from 'html2canvas';

// import QRCode from "qrcodejs2"; //二维码
import vueQr from 'vue-qr';
import { domain } from '../../utils/request';
import { mapGetters } from 'vuex';
import mooc from './components/mooc/index';
import custom from './components/custom/index';
import polyv from './components/polyv/index';

export default {
  components: {
    vueQr,
    platformCourseMulu,
    mooc,
    custom,
    polyv,
  },
  computed: {
    ...mapGetters(['phoneUrl']),
  },
  data() {
    return {
      codeType: undefined,
      clickDisabled: true,
      checkAll: false,
      isIndeterminate: true,
      // 课件弹出层加载
      download_load: true,
      // 选中课件数量
      pitchOn: 0,
      name_id_obj: undefined,
      // 下载课程资料列表
      download_courseList: [],
      download_courseIds: [],
      // 检查员推荐码输入是否禁用
      inputdisabled: false,
      isOpen_select: false,
      select_A: false,
      select_B: false,

      rolesShow: false,
      // 我的推荐码
      myJobNumber: undefined,
      // 二维码logo
      qrimageUrl: require('../../assets/image/cqc_logo1.png'),
      // 邀请码
      InvitationCode: null,

      // 禁用状态
      disabled: false,
      activeName: 'first',
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      input: '',
      tel: '',
      images: '../assets/index2.jpg',
      companyName: '',
      departmentName: '',
      name: '',
      email: '',
      phone: '',
      courseId: '',
      courseId2: localStorage.getItem('courseId'),
      id: '',
      ids: 1,
      title: '',
      startTime: '',
      endTime: '',
      content: '',
      unitPrice: '',
      way: '',
      imageUrl: '',
      // phoneUrl: "",
      phoneUrl2: '',
      browseAmount: '',
      totalSign: '',
      maxSign: '',
      status: '',
      signId: null,
      see: true,
      signAsOfTime: undefined,
      affirmTime: undefined,
      radio: '1',
      signTeacher: [],
      signStudent: [],
      token: false,
      queryForm: {
        // id: localStorage.getItem("signId2"),
        id: undefined,
      },
      // 测试二维码
      downloadData: {
        // http://cqcbm.baikegz.com:3084/#/upload?courseId=369
        // url: "http://cqcbm.baikegz.com:3084",
        // url: "http://cqcbm.baikegz.com:3084/#/upload?courseId=this.$route.query.id",
        // url:
        //   "http://192.168.10.103:8082/#/upload?courseId=" +
        //   localStorage.getItem("signId"),
        url: '',
        icon: '随便一张图片的地址也行',
      },
      // form: {
      //   name: "",
      //   region: "",
      //   region2: "",
      //   date1: "",
      //   input: "",
      //   date2: "",
      //   delivery: false,
      //   type: [],
      //   resource: "",
      //   desc: "",
      // },
      formMess: {
        name: '',
        phone: '',
        sex: '',
        sex0: '',
        sex1: '',
        unit: '',
        email: '',
        idCode: '',
        address: '',
        invoiceUp: '',
        codeType: '',
        referees: '', // 推荐人员代码
        referees1: '', // 选A时的推荐码
        referees2: '', // 选B时的推荐码
        // courseId: localStorage.getItem("courseId"),
        courseId: this.$route.query.id,
        // courseId: 25,
        value1: '',
        source: '3',
        option1: [
          { text: 'A:佰科培训工作人员推荐', value: '1' },
          { text: 'B:工厂检查人员推荐', value: '2' },
          { text: 'C:自行查询获知', value: '3' },
        ],
        option2: [
          // { text: "请选择推荐人员", value: "00号" },
          { text: '01', value: '01' },
          { text: '02', value: '02' },
          { text: '03', value: '03' },
          { text: '04', value: '04' },
          { text: '05', value: '05' },
          { text: '06', value: '06' },
          // { text: "工厂人员推荐，无代码", value: "06号" },
        ],
      },
      formRules: {
        // 姓名 单位 身份证 手机号
        // name unit idCode phone sex email address invoiceUp
        // 性别 邮箱 地址 发票抬头
        // name: [{ required: true, trigger: "blur", message: "请输入姓名" }],
        name: [
          { required: true, trigger: 'blur', message: '请输入姓名' },
          { required: true, message: '请输入中文名', trigger: 'blur' },
          {
            validator: function(rule, value, callback) {
              if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
                callback(new Error('请输入中文'));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        unit: [{ required: true, trigger: 'blur', message: '请输入单位' }],
        codeType: [
          { required: true, trigger: 'blur', message: '请选择证件类型' },
        ],
        idCode: [
          // { required: true, trigger: "blur", message: "身份证不能为空" },
          { required: true, message: '请输入证件号', trigger: 'blur' },

          /*{
            required: true,
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请输入正确的身份证号码",
            trigger: "blur",
          },*/
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            required: true,
            pattern: /^1[3|4|5|7|8|9][0-9]\d{8}$/,
            message: '请输入正确的手机号',
            trigger: 'blur',
          },
        ],
        sex: [{ required: true, trigger: 'blur', message: '请输入性别' }],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            required: true,
            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
            message: '请输入正确的邮箱',
            trigger: 'blur',
          },
        ],
        address: [{ required: true, trigger: 'blur', message: '请输入地址' }],
        invoiceUp: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入发票抬头(公司名称)',
          },
        ],
      },
      //
      option1: [
        { text: 'A:佰科培训工作人员推荐', value: '1' },
        { text: 'B:工作检查人员推荐', value: '2' },
        { text: 'C:自行查询获知', value: '3' },
      ],
      option2: [
        { text: '请选择推荐人员', value: '00号' },
        { text: '01', value: '01号' },
        { text: '02', value: '02号' },
        { text: '03', value: '03号' },
        { text: '04', value: '04号' },
        { text: '05', value: '05号' },
        { text: '工厂人员推荐，无代码', value: '06号' },
      ],
      // downloadData: {
      //   // http://cqcbm.baikegz.com:3084/#/upload?courseId=369
      //   // url: "http://cqcbm.baikegz.com:3084",
      //   // url: "http://cqcbm.baikegz.com:3084/#/upload?courseId=this.$route.query.id",
      //   url:
      //     "http://192.168.10.103:8082/#/upload?courseId=" +
      //     localStorage.getItem("signId"),
      //   icon: "随便一张图片的地址也行",
      // },
      data: {},
      courseEndTime: undefined,
      courseStartTime: undefined,
      courseType: null,
      activeNav: 'introduce',
      courseTree: [],
      certificateTypeOptions: [],
      tipVisible: false,
      confirmButtonText: '执行中...',
      confirmButtonDisabled: true,
      videoType: null,
      Topen: false,
      videoFile: {},
    };
  },
  filters: {
    format(time) {
      return formatDate(new Date(time), 'yyyy-MM-dd hh:mm:ss');
    },
    way(way) {
      return ['线上培训', '线下培训'][way];
    },
    repaceImg(imageUrl) {
      return 'http://' + window.location.host + '/images/' + imageUrl;
    },
    // 当标题字数超出时，超出部分显示XX此处限制超出1位即触发隐藏效果
    ellipsis(value) {
      if (!value) return '';
      if (value.length > 1) {
        return value.slice(0, 1) + '**';
      }
      return value;
    },
  },
  //在这里调用axios请求方法
  created() {
    // getConfigKeyiphone("iphoneUrl").then((response) => {
    //   console.log("移动端response", response);
    //   this.phoneUrl = response.msg;
    //   this.$store.dispatch("setPhoneUrl", response.msg);
    // });
    // this.getSignup();
    // this.phoneUrl = this.$phoneUrl
    // console.log("",this.phoneUrl);
    this.token = getToken() || false;
    this.myJobNumber = localStorage.getItem('myJobNumber');
    console.log('token', this.token);
    console.log('手机Url', this.phoneUrl);
    this.getDicts('bm_certificate_class').then((res) => {
      this.certificateTypeOptions = res.data;
    });
    // this.getList();
    if (this.token) {
      this.loadForm();
      this.getInfo();
    } else {
      this.clickDisabled = false;
      setTimeout(() => {
        this.InvitationCode = `${this.phoneUrl}signup/?id=${this.$route.query.id}`;
      }, 200);
      // if (this.$store.state.user.phoneUrl) {
      //   this.InvitationCode = `${this.$store.state.user.phoneUrl}signup/?id=${this.$route.query.id}`;
      //   this.phoneUrl = this.$store.state.user.phoneUrl;
      //   this.clickDisabled = false
      // } else {
      //   getConfigKeyiphone("iphoneUrl").then((response) => {
      //     this.phoneUrl = response.msg;
      //     this.$store.dispatch("setPhoneUrl", response.msg);
      //     this.InvitationCode = `${this.phoneUrl}signup/?id=${this.$route.query.id}`;
      //     this.clickDisabled = false
      //   });
      // }
    }
    this.getSignup();
    // this.signId = localStorage.getItem("signId");
    // this.convert();
    // console.log("报名this.signId");
    // console.log(this.signId);

    // 测试
    // // 测试
    // const user = this.$store.getters.user;
    // console.log("获取用户信息111", user);
    // console.log("获取用户信息222", user.username);
  },
  mounted() {
    this.getList();
    // // 测试
    // const user = this.$store.getters.user;
    // console.log("获取用户信息111", user);
    // console.log("获取用户信息222", user.username);
    // // this.signId = localStorage.getItem("signId");
    // console.log("获取用户signId", this.signId);
    // this.downloadData.url = "http://192.168.10.103:8082/#/upload?courseId=" + this.signId;
    // console.log("获取用户信息url", this.downloadData.url);
    console.log('获取工号new', this.myJobNumber);
    // if(this.$route.query.jobNumber){
    //   console.log("存入推荐码");
    //   this.$store.dispatch("setjobNumber", this.$route.query.jobNumber)
    // }

    if (this.$route.query.jobNumber) {
      this.$store.dispatch('setjobNumber', this.$route.query.jobNumber);
      let jobNumber_new = this.$route.query.jobNumber;
      console.log(
        'this.$route.query.jobNumber课程详情页检查员工号：',
        jobNumber_new,
        localStorage.getItem('myJobNumber')
      );
      console.log('推荐码长度：', jobNumber_new.length);
      if (jobNumber_new.length != 5) {
        if (jobNumber_new == localStorage.getItem('myJobNumber')) {
          this.formMess.referees = '';
          this.isOpen_select = false;
          this.inputdisabled = false;
          this.formMess.source = '3';
          this.select_B = false;
        } else {
          console.log('有工号 但是和自己的不一样');
          this.formMess.referees2 = jobNumber_new;
          this.isOpen_select = true;
          this.inputdisabled = true;
          this.formMess.source = '1';
          this.select_B = true;
        }
      } else {
        if (jobNumber_new == localStorage.getItem('myJobNumber')) {
          this.formMess.referees = '';
          this.isOpen_select = false;
          this.inputdisabled = false;
          this.formMess.source = '3';
          this.select_B = false;
        } else {
          console.log('有工号 但是和自己的不一样');
          this.formMess.referees2 = jobNumber_new;
          this.isOpen_select = true;
          this.inputdisabled = true;
          this.formMess.source = '2';
          this.select_B = true;
        }
      }
    } else if (this.$store.state.user.jobNumber) {
      let jobNumber_new = this.$store.state.user.jobNumber;
      console.log(
        'this.$store.state.user.jobNumber课程详情页检查员工号：',
        jobNumber_new,
        localStorage.getItem('myJobNumber')
      );
      console.log('推荐码长度2222222：', jobNumber_new.length);
      if (jobNumber_new.length != 5) {
        if (jobNumber_new == localStorage.getItem('myJobNumber')) {
          this.formMess.referees = '';
          this.isOpen_select = false;
          this.inputdisabled = false;
          this.formMess.source = '3';
          this.select_B = false;
        } else {
          this.formMess.referees2 = jobNumber_new;
          this.isOpen_select = true;
          this.inputdisabled = true;
          this.formMess.source = '1';
          this.select_B = true;
        }
      } else {
        if (jobNumber_new == localStorage.getItem('myJobNumber')) {
          this.formMess.referees = '';
          this.isOpen_select = false;
          this.inputdisabled = false;
          this.formMess.source = '3';
          this.select_B = false;
        } else {
          this.formMess.referees2 = jobNumber_new;
          this.isOpen_select = true;
          this.inputdisabled = true;
          this.formMess.source = '2';
          this.select_B = true;
        }
      }
    } else {
      console.log('没有任何推荐吗');
      this.isOpen_select = false;
      this.inputdisabled = false;
      this.formMess.source = '3';
      this.select_B = false;
    }
  },
  methods: {
    handleApply() {
      // if (this.signId == null) {
      //   this.Topen = false
      //   this.tipVisible = true
      //   this.confirmButtonDisabled = true
      //   this.confirmButtonText = '执行中...'
      //   setTimeout(() => {
      //     this.confirmButtonDisabled = false;
      //     this.confirmButtonText = '确 定'
      //   }, 3000)
      // }
    },
    // 试看
    handleTryOnSeeing(catalogue) {
      const {
        file: { fileType },
      } = catalogue;
      this.videoType =
        fileType === 'mooc_video'
          ? 'mooc'
          : fileType === 'cloud_video'
          ? 'polyv'
          : 'custom';
      this.videoFile = catalogue;
      this.Topen = true;
    },
    // 关闭提示弹窗
    handleTipClose() {
      this.tipVisible = false;
      this.dialogVisible = true;
      this.getList();
    },

    handleOpen(item, index) {
      let courseTree = this.courseTree;
      courseTree[index] = item;
      this.courseTree = JSON.parse(JSON.stringify(courseTree));
    },
    // 获取课程目录
    getCoursePeriodTree() {
      getCoursePeriodTree({
        courseId: this.$route.query.id,
        isFront: 1,
      }).then((res) => {
        this.courseTree = res.data;
      });
    },
    showClick() {
      console.log('点击');
    },
    unitBlur() {
      if (this.formMess.invoiceUp == undefined || this.formMess.invoiceUp == '')
        this.formMess.invoiceUp = this.formMess.unit;
    },
    close_page() {
      this.download_courseIds = [];
    },
    handleCheckedCitiesChange(value) {
      // console.log(value);
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.download_courseList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.download_courseList.length;
    },
    handleCheckAllChange(val) {
      // console.log(val);
      this.download_courseIds = val
        ? this.download_courseList.map((res) => {
            return res.name + '.' + res.suffix;
          })
        : [];
      this.isIndeterminate = false;
    },
    // 点击下载课件
    submitDownload() {
      console.log(this.download_courseIds);
      let ids = [],
        _this = this;
      this.download_courseIds.map((item) => {
        ids.push(this.name_id_obj[item]);
      });
      if (ids.length !== 0) {
        this.$confirm('是否确认下载所选课程相关的课件?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(function() {
            console.log(ids.toString());
            window.open(
              process.env.VUE_APP_BASE_API +
                '/bm/material/download/' +
                ids.toString(),
              '_self'
            );
            _this.dialogVisible3 = false;
            _this.download_courseIds = [];
          })
          .catch(function() {});
      } else {
        this.$message({
          message: '请选择下载课件',
          type: 'warning',
        });
      }
    },

    // 点击 下载课件弹出层
    download_course() {
      this.dialogVisible3 = true;
      this.download_load = true;
      let name_id = {};
      getDownloadList({ courseId: this.$route.query.id, isDel: 'N' }).then(
        (res) => {
          this.download_load = false;
          res.rows.map((item) => {
            name_id[item.name + '.' + item.suffix] = item.id;
          });
          this.name_id_obj = name_id;
          console.log('文件名andID号对象：', this.name_id_obj);
          console.log('获取课程下载列表：', res);
          this.download_courseList = res.rows;
        }
      );
    },

    // 填写报名信息时 选择框改变时
    select_click(newvalue) {
      console.log(newvalue);
      let value = newvalue;
      switch (value) {
        case '1':
          (this.select_A = true), (this.select_B = false);
          break;
        case '2':
          this.select_A = false;
          this.select_B = true;
          break;

        default:
          this.select_A = false;
          this.select_B = false;
          break;
      }
    },

    getInfo() {
      // this.phoneUrl = this.$store.state.user.phoneUrl;
      console.log('获取vuex中的手机地址', this.phoneUrl);
      getStudent().then((res) => {
        this.roles = res.data.params.roles;
        if (this.roles.indexOf('检查员') > -1) {
          this.rolesShow = true;
          // this.InvitationCode = `${this.$store.state.user.phoneUrl}signup/?id=${this.$route.query.id}&jobNumber=${this.myJobNumber}`;
          setTimeout(() => {
            this.InvitationCode = `${this.phoneUrl}signup/?id=${this.$route.query.id}&jobNumber=${this.myJobNumber}`;
          }, 200);
          this.clickDisabled = false;
        } else {
          this.rolesShow = false;
          // this.InvitationCode = `${this.$store.state.user.phoneUrl}signup/?id=${this.$route.query.id}`;
          setTimeout(() => {
            this.InvitationCode = `${this.phoneUrl}signup/?id=${this.$route.query.id}`;
          }, 200);
          this.clickDisabled = false;
        }
        console.log('获取getInfo', this.roles);
      });
    },
    savePic() {
      console.log(this.$refs.bill);
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      html2canvas(this.$refs.bill, { scale: 1 }).then((canvas) => {
        // console.log("保存图片", canvas);
        let imageUrl = canvas.toDataURL('image/png'); // 将canvas转成base64图片格式
        // console.log("保存图片后的url", imageUrl);
        let a = document.createElement('a');
        //设置a标签的href属性（将canvas变成png图片）
        a.href = imageUrl;
        a.download = '佰科平台课程邀请码';
        a.click();
        this.$message({
          message: '下载成功!',
          type: 'success',
        });
      });
    },
    // 复制课程地址链接
    copyCourseAddress() {
      // var url = `${this.$store.state.user.phoneUrl}signup/?id=${this.$route.query.id}&jobNumber=${this.myJobNumber}`;
      const courseName = `&courseName=${this.title}`;
      var url = `${this.InvitationCode}${courseName}`;
      var oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand('Copy'); // 执行浏览器复制命令
      oInput.className = 'oInput';
      oInput.style.display = 'none';
      this.$message({ message: '复制成功!', type: 'success' });
    },
    //去上课
    cqcLogin() {
      if (isWeiXin()) {
        this.$alert(
          '微信浏览器尚不支持直播课程学习，请使用其他浏览器打开网站登录学习！',
          '提示',
          {
            confirmButtonText: '确定',
            callback: (action) => {},
          }
        );
        return null;
      }
      const user = this.$store.getters.user;
      window.open(
        // "http://guangzhou.baikegz.com/cqc-baike-web/platformCourseDetail?id="+this.$route.query.id,
        // "http://113.108.126.152:3098/cqc-baike-web/platformCourseDetail?id="+this.$route.query.id,
        // "https://cqc.pinxueyun.com/cqc-baike-web/platformCourseDetail?id="+this.$route.query.id,
        domain + 'platformCourseDetail?id=' + this.$route.query.id,
        '_blank'
      );
      // window.open(
      //   "http://cqc.baikegz.com/login?au=1&us=" +
      //     user.username +
      //     "&pw=" +
      //     user.password +
      //     "&goto=" +
      //     "http://cqc.baikegz.com/my/courses/learning",
      //   "_blank"
      // );
      // window.open(
      //   "http://cqc.baikegz.com/login?au=1&us=" +
      //     user.username +
      //     "&pw=" +
      //     user.password,
      //   "_blank"
      // );
    },
    //
    getList() {
      let id = this.$route.query.id;

      // 存储
      this.courseId2 = this.$route.query.id;
      localStorage.setItem('courseId', this.$route.query.id);
      localStorage.setItem('signId', null);
      console.log('查询课程详情id');
      console.log(id);
      if (getToken()) {
        // this.phoneUrl = this.$store.state.user.phoneUrl;
        getCourseJoinUser(id).then((res) => {
          console.log('查询课程成功111111111！');
          console.log(res);
          this.title = res.data.title;
          this.courseStartTime = res.data.courseStartTime;
          this.courseEndTime = res.data.courseEndTime;
          this.signAsOfTime = res.data.signAsOfTime;
          this.way = res.data.way;
          this.imageUrl = res.data.imageUrl;
          this.content = res.data.content;
          this.status = res.data.status;
          this.courseId = res.data.signId;
          this.signId = res.data.signId;
          this.unitPrice = res.data.unitPrice;
          this.affirmTime = res.data.affirmTime;
          this.totalSign = res.data.totalSign;
          this.maxSign = res.data.maxSign;
          this.courseType = res.data.courseType;
          if (this.courseType === '1') this.getCoursePeriodTree();
          // 判断是否为点播课程，是则显示课程目录
          localStorage.setItem('signId', res.data.signId);
          localStorage.setItem('signId2', null);
          console.log('报名this.signId2222222222222222222带token');
          console.log(this.signId);
          // 测试
          const user = this.$store.getters.user;
          console.log('获取用户信息111', user);

          // this.signId = localStorage.getItem("signId");
          // "http://192.168.10.105:8082/#/login?courseId=" +
          //  "http://cqcbm.baikegz.com:3084/#/login?courseId=" +
          console.log('获取用户signId', this.signId);
          // https://m.pinxueyun.com/#/
          this.downloadData.url =
            // `${this.phoneUrl}user/login?courseId=` +
            `https://m.pinxueyun.com/#/user/login?courseId=` +
            this.signId +
            '&username=' +
            user.username +
            '&password=' +
            user.password +
            '&_t=' +
            new Date().getTime();
          console.log('获取用户信息url', this.downloadData.url);
          //   if (res.data.status == 1 || res.data.status == 3) {
          //     this.see = false;
          //   } else {
          //     this.see = true;
          //   }
        });
      } else {
        getCoursepc(id).then((res) => {
          console.log('查询课程详情成功 哈哈哈');
          console.log(res);
          this.title = res.data.title;
          this.courseStartTime = res.data.courseStartTime;
          this.courseEndTime = res.data.courseEndTime;
          this.signAsOfTime = res.data.signAsOfTime;
          this.way = res.data.way;
          this.imageUrl = res.data.imageUrl;
          this.content = res.data.content;
          this.status = res.data.status;
          this.courseId = res.data.signId;
          this.unitPrice = res.data.unitPrice;
          this.totalSign = res.data.totalSign;
          this.maxSign = res.data.maxSign;
          this.data = res.data;
          // localStorage.setItem("signId", null);
          this.signId = localStorage.getItem('signId');
          this.courseType = res.data.courseType;
          if (this.courseType === '1') this.getCoursePeriodTree();
          console.log('打印报名this.signId88888888888888888888888888888');
          console.log(this.signId);
          // localStorage.setItem("signId2", null);
          // localStorage.setItem("signId2", res.data.id);
          if (res.data.status == 1 || res.data.status == 3) {
            this.see = false;
          } else {
            this.see = true;
          }
        });
      }
    },
    loadForm() {
      getStudent().then((res) => {
        this.formMess.name = res.data.name;
        this.formMess.phone = res.data.user.userName;
        this.formMess.sex = res.data.sex;
        this.formMess.unit = res.data.unit;
        this.formMess.email = res.data.email;
        this.formMess.idCode = res.data.idCode;
        this.formMess.address = res.data.address;
        this.formMess.invoiceUp = res.data.invoiceUp;
        this.formMess.codeType = res.data.codeType ? res.data.codeType : '';
        this.codeType = res.data.codeType;
      });
    },
    submitForm1() {
      console.log('99999');
      this.$router.push({
        name: 'signProgress',
        query: {
          id: localStorage.getItem('courseId'),
        },
      });
    },
    // 查询授课老师
    getSignup() {
      let id = this.$route.query.id;

      // getCourse(this.id).then((res) => {
      signTeacher(id).then((res) => {
        console.log('查询授课老师11111成功 哈哈哈');
        // console.log(res.rows[0]);
        console.log(res);
        // console.log(res.rows[0].name);
        if (res.rows.length > 0) {
          this.signTeacher = res.rows[0];
        } else {
          console.log('数组为空');
        }
      });
      signStudent(id).then((res) => {
        console.log('查询最新学员成功 哈哈哈');
        console.log(res.rows);
        this.signStudent = res.rows;
      });
    },
    // 我要报名弹框
    dialogVisiblebtn: function() {
      // this.getList2();
      if (getToken()) {
        this.tipVisible = true;
        this.confirmButtonDisabled = true;
        this.confirmButtonText = '执行中...';
        setTimeout(() => {
          this.confirmButtonDisabled = false;
          this.confirmButtonText = '确 定';
        }, 3000);
      } else {
        this.$router.push({
          path: '/user/login',
          query: {
            id: this.$route.query.id,
            jobNumber: this.$route.query.jobNumber,
          },
        });
      }
      // this.dialogVisible = true;
    },

    /** 提交按钮 */
    submitScheduleForm: function() {
      this.unitBlur();
      this.$refs['formMess'].validate((valid) => {
        if (valid) {
          console.log('验证成功');
          console.log(this.formMess);
          if (this.formMess.source == '1') {
            this.formMess.referees =
              this.formMess.referees1 != ''
                ? this.formMess.referees1
                : this.formMess.referees2;
          } else if (this.formMess.source == '2') {
            this.formMess.referees = this.formMess.referees2;
          } else {
            this.formMess = this.formMess;
          }
          console.log(this.formMess);
          this.disabled = true;
          // 测试
          if (this.disabled == true) {
            addSignUp(this.formMess).then((res) => {
              this.disabled = true;
              console.log(res);

              localStorage.setItem('signId2', res.data.id);
              this.queryForm.id = res.data.id;
              // this.dialogVisible = true;
              // this.open = true;
              if (res.code === 200) {
                // localStorage.setItem("signId2", res.data.id);
                this.$message({
                  showClose: true,
                  message: '提交成功~',
                  type: 'success',
                });
                //
                this.getList();
                //判断是否免费
                if (this.unitPrice > 0) {
                  this.dialogVisible2 = true;
                } else if (this.unitPrice <= 0) {
                  // name: 'signProgress', query: { id: courseId2 }
                  this.$router.push({
                    name: 'signProgress',
                    query: { id: this.$route.query.id },
                  });
                  console.log('哈哈999');
                }

                // this.dialogVisible2 = true;
                // this.convert();
                setTimeout(this.convert(), 3000);
              }
            });
          }
        }
      });
    },
    //查询报名课程
    convert() {
      listCoursepayment(this.queryForm).then((res) => {
        console.log('查询课程成功666！');
        console.log(res);
        this.name = res.data.name;
        this.phone = res.data.phone;
        this.title = res.data.title;
        this.unitPrice = res.data.unitPrice;
        // this.formMess.id = res.data.id;
      });
      // this.qrcode();
      // payment().then((res) => {
      //   console.log("提交成功888");
      //   console.log(res);
      // });
    },
    //
    handleClick(tab, event) {
      console.log(tab, event);
    },
    closeShadow() {
      this.dialogVisible = false;
      this.dialogVisible2 = false;
      this.dialogVisible3 = false;
      // console.log(9999);
      this.qrcode();
    },
    handleClose(done) {
      done();
      this.dialogVisible = false;
      this.dialogVisible2 = false;
      // this.$confirm("确认关闭？")
      //   .then(() => {
      //     done();
      //   })
      //   .catch(() => {});
    },
    open() {
      this.$alert('这是一段内容', '标题名称', {
        confirmButtonText: '确定',
        callback: (action) => {
          this.$message({
            type: 'info',
            message: `action: ${action}`,
          });
        },
      });
    },
    codeTypeChange(value) {
      console.log(value);
      console.log(this.formMess.codeType);
      // this.formMess.codeType = value;
    },
  },
  watch: {
    Topen(val) {
      if (!val) this.videoType = null;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: auto;
  background: #f8f8f8;
  padding-top: 26px;
  padding-bottom: 138px;
  display: flex;

  .idx-content {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;

    .class-nav {
      margin-bottom: 37px;
    }

    .class-box-1 {
      height: 236px;
      // border: 1px solid teal;
      .class-box-1L {
        width: 1018px;
        height: auto;
        background: #ffffff;
        float: left;

        ::v-deep.el-tabs__header {
          margin: 0;
          padding: 10px 114px 0;
        }

        ::v-deep.el-tabs__nav-wrap {
          &:after {
            background-color: transparent;
          }
        }

        ::v-deep.el-tabs__item {
          font-size: 18px;
          font-weight: bold;
        }

        // padding-bottom: 70px;
        // position: relative;
        .class-box-1L-1 {
          width: 1018px;
          height: auto;
          // height: 57px;
          line-height: 57px;
          background: #ffffff;
          box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
          padding-left: 114px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          margin-bottom: 4px;
          display: flex;
          justify-content: space-between;
          position: relative;

          .price {
            font-size: 26px;
            font-family: Microsoft YaHei;
            font-weight: 700;
            color: #003cff;
            position: absolute;
            right: 145px;
          }

          .title-box {
            width: 73%;
            height: auto;
            line-height: 30px;
            padding-top: 12px;
            // border: 1px solid teal;
          }

          .share {
            display: inline-block;
            width: 35px;
            height: 35px;
            line-height: 35px;
            background: red;
            border-radius: 50%;
            margin-right: 10px;
            background: url(../../assets/image/share.png);
            text-indent: -9999px;
            margin-right: 80px;
          }
        }

        .class-box-1L-2 {
          width: 1018px;
          height: 175px;
          background: #ffffff;
          box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
          padding-left: 114px;
          position: relative;

          .class-box-1L-2-1 {
            padding-top: 25px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
              padding-right: 18px;
            }
          }

          .class-box-1L-2-4 {
            width: 130px;
            height: 41px;
            line-height: 41px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            background: linear-gradient(
              -54deg,
              rgba(29, 112, 234, 0.89),
              rgba(72, 140, 240, 0.89)
            );
            border-radius: 4px;
            position: absolute;
            top: 115px;
            right: 79px;
            cursor: pointer;
          }

          .download_btn {
            position: absolute;
            top: 115px;
            right: 220px;
          }

          // .classDetail-box {
          //   width: 100%;
          //   height: 550px;
          //   border: 1px solid teal;
          // }
        }
      }

      .class-box-1R {
        width: 262px;
        height: 236px;
        background: #ffffff;
        float: right;

        .class-box-1R-1 {
          width: 262px;
          height: 57px;
          line-height: 57px;
          padding-left: 19px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          background: #ffffff;
          box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
        }

        .class-box-1R-2 {
          img {
            width: 80px;
            height: 80px;
            background: #ffffff;
            border-radius: 50%;
            margin-left: 91px;
            margin-top: 24px;
          }

          .class-box-1R-2b {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 21px;
            background: #ffffff;

            .class-bs-1 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            }

            .class-bs-2 {
              width: 1px;
              height: 20px;
              background: #c0c0c0;
              margin: 0 18px;
              display: inline-block;
            }

            .class-bs-3 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;
            }
          }
        }
      }
    }

    .class-box-2 {
      margin-top: 27px;

      .class-box-1L {
        padding-bottom: 70px;
      }

      ::v-deep .class-box-1L-2box img {
        width: 100%;
      }

      ::v-deep .class-box-1L-2box .ql-align-justify {
        text-align: left;
      }

      ::v-deep .class-box-1L-2box p img {
        width: 100%;
      }

      .class-box-1L-2box {
        width: 795px;
        height: auto !important;
        margin: 0 auto;
        line-height: 30px;
        text-align: justify;
        padding-top: 30px;

        ::v-deep a {
          text-decoration: underline;
        }

        img {
          width: 550px;
          height: 336px;
          margin-left: 74px;
          margin-top: 66px;
          margin-bottom: 60px;
        }

        p {
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #27333b;
          line-height: 28px;
          margin-top: 32px;

          img {
            width: 100% !important;
          }
        }
      }

      .class-box-1R {
        height: auto !important;

        .class-box-1R-2 {
          padding-bottom: 25px;
          background: #fff;
          /* overflow: hidden; */

          .class-box-1R-2b {
            height: 65px;
            margin-top: 0 !important;
            width: 100%;
            display: inline-block;

            img {
              width: 40px;
              height: 40px;
              border-radius: 100%;
              float: left;
              // padding-right: 13px;
              margin-left: 26px;
            }

            .class-bs-2a {
              float: left;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 85px;
              color: #000000;
              padding-left: 16px;
            }
          }
        }
      }
    }
  }
}

.try-on-seeing-dialog {
  ::v-deep.el-dialog__headerbtn {
    opacity: 1 !important;
    border: none !important;
  }

  ::v-deep.el-dialog__body {
    padding-top: 0;
  }
}

::v-deep .el-dialog__header {
  border-radius: 30px;
}

::v-deep button.el-button.class-btn2.el-button--disabled.is-disabled {
  opacity: 0.7;
}

::v-deep .el-dialog {
  .el-dialog__headerbtn {
    position: absolute;
    top: -4px;
    right: -2%;
    padding: 0;
    background: 0 0;
    border: 1px solid teal;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    width: 10%;
    height: 50px;
    opacity: 0;
    z-index: 99999;
  }

  .classDetail-box {
    width: 100%;
    height: 550px;
    // border: 1px solid teal;

    .classDetail-box2 {
      width: 90%;
      height: 550px;
      // border: 1px solid tan;
      margin: 0 auto;

      .classDetail-box-1 {
        width: 100%;
        height: 215px;
        // border: 1px solid teal;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .classDetail-box-1L {
          width: 56%;
          height: 235px;
          border-right: 1px solid rgba(64, 64, 64, 0.1);
          padding-right: 76px;

          ::v-deep .el-form-item__label {
            text-align: left;
            vertical-align: middle;
            float: left;
            font-weight: bold;
            color: #393939;
            line-height: 40px;
            padding: 0 6px 0 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }

          ::v-deep .el-input__inner {
            -webkit-appearance: none;
            background-color: #fff;
            background-image: none;
            border-radius: 4px;
            border: none;
            border-bottom: 1px solid #e5e5e5;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            color: #606266;
            display: inline-block;
            font-size: inherit;
            height: 40px;
            line-height: 40px;
            outline: 0;
            padding: 0 0px;
            -webkit-transition: border-color 0.2s
              cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 80%;
          }

          .classDetail-inp-1 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 51px;

            .my-R-1L {
              width: 25%;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #393939;
            }

            ::v-deep .el-input__inner {
              -webkit-appearance: none;
              background-color: #fff;
              background-image: none;
              border-radius: inherit;
              border: none;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              color: #606266;
              display: inline-block;
              font-size: inherit;
              height: 40px;
              line-height: 40px;
              outline: 0;
              padding: 0 15px;
              -webkit-transition: border-color 0.2s
                cubic-bezier(0.645, 0.045, 0.355, 1);
              transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
              width: 83%;
              border-bottom: 1px solid #e5e5e5;
            }
          }
        }

        .classDetail-box-1R {
          width: 49%;
          height: 215px;
          margin-left: 6%;
          border-right: none;
        }
      }

      .classDetail-box-2 {
        .tjInput {
          .el-input__inner {
            padding-left: 45px;
          }

          // padding-left: 50px;
        }

        margin-top: 39px;

        ::v-deep .el-form-item__label {
          text-align: right;
          vertical-align: middle;
          float: left;

          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(119, 119, 119, 1);
          line-height: 40px;
          padding: 0 12px 0 0;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          text-align: left;
        }
      }

      .class-top {
        width: 20%;
        height: 65px;
        line-height: 65px;
        text-align: center;
        background: #3d7eff;
        -webkit-box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.3);
        border-radius: 19px;
        position: absolute;
        top: -34px;
        left: 40%;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }

      .class-btn {
        width: 341px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        background: #3d7eff;
        border-radius: 6px;

        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        // margin: 0 auto;
        // margin-top: 2%;
        position: absolute;
        left: 33%;
        bottom: 45px;
        cursor: pointer;
      }

      .class-btn2 {
        width: 341px;
        height: 52px;
        // line-height: 52px;
        text-align: center;
        background: #3d7eff;
        border-radius: 6px;

        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        // margin: 0 auto;
        // margin-top: 2%;
        position: absolute;
        left: 33%;
        bottom: 45px;
        cursor: pointer;
      }

      .class-btn3 {
        position: absolute;
        left: 23%;
        bottom: 45px;
      }

      .class-close {
        position: absolute;
        right: -0.5%;
        top: -5px;
      }

      .class-close-new {
        position: absolute;
        right: -1.3%;
        top: -9px;
      }
    }
  }

  .classDetail-box-new {
    width: 100%;
    height: 360px;
    // border: 1px solid red;
    .classDetail-box2-new {
      width: 400px;
      height: 250px;
      // border-bottom: 1px solid #eee;
      // border-left: 1px solid #eee;
      .msg {
        width: 100%;
        height: 250px;
        font-size: 20px;
        color: #606266;
      }

      .download-list {
        width: 100%;
        height: 250px;
        overflow-x: hidden;
        // overflow-y: scroll;
        li {
          height: 40px;

          .el-checkbox {
            height: 40px;
            line-height: 40px;
            width: 400px;
            white-space: nowrap;
            // overflow: hidden;
            display: inherit;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;

            .el-checkbox__label {
              display: inline-block;
              padding-left: 10px;
              position: relative;
              top: -1px;
              height: 15px;
              line-height: 15px;
              font-size: 14px;
              width: 86%;
              display: inline-block;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .el-checkbox:hover {
            color: #3d7eff;
          }
        }
      }

      .download-list::-webkit-scrollbar {
        width: 10px;
        height: 5px;
        // background: red;
      }

      .download-list::-webkit-scrollbar-thumb {
        border-radius: 100px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #3d7eff;
      }

      .download-list::-webkit-scrollbar-track {
        -webkit-box-shadow: inset #eee;
        border-radius: 100px;
        background: rgba(0, 0, 0, 0.1);
      }

      .select-amount {
        // border: 1px solid yellow;
        height: 50px;
        line-height: 50px;
        font-size: 15px;
        color: #27333b;

        .red {
          color: red;
        }
      }

      .class-btn4 {
        width: 275px;
        height: 45px;
        font-size: 16px;
        position: absolute;
        left: 24%;
        bottom: 45px;
      }
    }

    .course_list {
      display: flex;
      align-items: center;
    }
  }

  .class-sucess {
    width: 100%;
    height: 600px;
    // border: 1px solid teal;
    .class-sucessbox {
      width: 90%;
      height: 600px;
      // border: 1px solid tan;
      margin: 0 auto;
      position: relative;

      .class-sucess-1 {
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #27333b;
        padding-left: 16px;
      }

      .class-sucess-2 {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff0000;
        opacity: 0.82;
        margin-top: 13px;
        margin-bottom: 22px;
        padding-left: 16px;
      }

      .class-sucess-3 {
        width: 100%;
        height: 323px;
        background: #f2f2f2;
        border-radius: 6px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #27333b;
        opacity: 0.82;
        padding-left: 16px;

        .class-suc-s1 {
          font-size: 15px;
          font-family: Microsoft YaHei;

          font-weight: bold;
          color: #27333b;
        }

        .class-sucess-3-1 {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #27333b;
          padding-top: 27px;
          margin-bottom: 22px;
        }

        .class-sucess-3-2 {
          margin-bottom: 22px;

          .class-sucess-3-2b {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #d67c32;
            padding-left: 10px;
          }
        }

        .class-sucess-3-3 {
          line-height: 25px;
        }

        .class-sucess-3-8 {
          width: 96%;
          margin-top: 22px;
          line-height: 17px;
        }
      }

      .class-sucess-4 {
        margin-top: 19px;
        width: 100%;
        height: 127px;
        position: relative;

        .class-sucess-4L {
          width: 126px;
          height: 127px;
          // border: 1px solid tan;
          float: left;

          img {
            position: relative;
            width: 126px;
            height: 127px;
            top: -1px;
            left: 0%;
          }
        }

        .class-sucess-4R {
          position: absolute;
          left: 18%;
          bottom: 5px;
          line-height: 18px;
        }
      }

      img {
        position: absolute;
        right: -8.5%;
        top: -66px;
      }

      .class-top {
        width: 20%;
        height: 65px;
        line-height: 65px;
        text-align: center;
        background: #3d7eff;
        -webkit-box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.3);
        border-radius: 19px;
        position: absolute;
        top: -93px;
        left: 37%;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }

      .suc-btn {
        width: 341px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        background: #3d7eff;
        border-radius: 6px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin: 0 auto;
        margin-top: 25px;
        cursor: pointer;
      }
    }
  }
}

.el-dialog__wrapper {
  &.tip-dialog {
    ::v-deep.el-dialog__body {
      padding: 12px 20px;

      span {
        line-height: 18px;
      }
    }
  }
}
</style>
