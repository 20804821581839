/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tv': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M810.394 258.509h-211.15L733.339 146.79c13.056-10.854 14.796-30.208 3.942-43.264a30.705 30.705 0 00-43.264-3.942L514.56 249.088l-175.872-149.3a30.746 30.746 0 00-43.315 3.534 30.787 30.787 0 003.533 43.315l131.788 111.872H222.106c-68.813 0-124.826 56.013-124.826 124.825v411.802c0 68.813 56.013 124.826 124.826 124.826h588.236c68.813 0 124.826-56.013 124.826-124.826V383.334c.051-68.812-55.962-124.825-124.774-124.825zm63.385 536.627c0 34.97-28.416 63.386-63.385 63.386H222.106c-34.97 0-63.386-28.416-63.386-63.386V383.334c0-34.97 28.467-63.385 63.386-63.385h588.236c34.97 0 63.386 28.416 63.386 63.385v411.802z" _fill="#2c2c2c"/><path pid="1" d="M433.818 764.826c-12.647 0-25.344-3.328-36.864-10.036-22.836-13.26-36.455-36.966-36.455-63.385v-211.2c0-26.42 13.62-50.125 36.455-63.386 22.835-13.26 50.176-13.414 73.164-.307l185.344 105.574c23.194 13.21 37.018 37.018 37.018 63.693 0 26.675-13.824 50.483-37.018 63.693L470.118 755.046c-11.366 6.503-23.808 9.78-36.3 9.78zm.05-296.55c-2.56 0-4.71.92-5.99 1.689-2.201 1.28-5.888 4.3-5.888 10.291v211.149c0 5.939 3.687 9.011 5.888 10.291 2.202 1.28 6.708 2.97 11.879.051L625.1 596.173c5.222-2.97 5.99-7.731 5.99-10.343 0-2.56-.768-7.372-5.99-10.342L439.757 469.914c-2.15-1.23-4.096-1.639-5.888-1.639z" _fill="#2c2c2c"/>'
  }
})
