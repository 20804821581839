<template>
  <div class="video-wrapper">
    <video id="video" class="video-js vjs-default-skin vjs-big-play-centered"
           controlsList="nodownload" disablePictureInPicture>
      <source :src="videoUrl" type="video/mp4">
    </video>
  </div>
</template>

<script>
import {getMiddleCustom} from '@/api/course/course'

export default {
  props: {
    videoFile: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      video: null,
      videoUrl: '',
      tryOnSeeingTime: 0
    }
  },
  methods: {
    async init() {
      const {file: {fromType, fileUrl}, tryWatch,id} = this.videoFile
      this.tryOnSeeingTime = tryWatch * 60
      if (fromType === 'middle') {
        const {data: {url}} = await getMiddleCustom({periodId: id})
        this.videoUrl = url
      } else {
        this.videoUrl = fileUrl
      }
      setTimeout(() => {
        this._initVideo()
      })
    },
    _initVideo() {
      const _that = this
      this.video = this.$video('video', {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: true,
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: "auto",
        //设置视频播放器的显示高度（以像素为单位）
        width: "640px",
        height: "600px",
        src: this.videoUrl
      }, function onPlayerReady() {
        //这里绑定事件
        const myPlayer = this;
        let flat = true
        myPlayer.on('timeupdate', function () {
          const time = myPlayer.currentTime();
          if (time > _that.tryOnSeeingTime && _that.tryOnSeeingTime > 0) {
            myPlayer.currentTime(_that.tryOnSeeingTime)
            myPlayer.pause()
            if (flat) {
              flat = false
              _that.$alert('试看已结束，请报名后继续学习！', '提示', {
                confirmButtonText: '确认',
                showClose: false,
                callback: action => {
                  _that.$emit('handleApply')
                  flat = true
                }
              })
            }
          }
        })
      })
    }
  },
  destroyed() {
    this.video.dispose()
  },
  mounted() {
    this.init()
  },
  watch: {
    videoFile: {
      handler(val) {
        // this.video.src({
        //   src: val.fileUrl
        // })
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.video-wrapper {
  width: 600px;
}
</style>
<style>
.vjs-fullscreen-control{
  display: none!important;
}
</style>
