/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'catalogue': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M578.3 338.3c-27-11.8-10.6-216.7-14.3-274.3H258.6c-44.2 0-80.1 35.9-80.1 80v736c0 44.2 35.9 80 80.1 80h506.8c44.2 0 80.1-35.9 80.1-80V352.1c-3.8-11.7-239.8 2.6-267.2-13.8zm-257.4 97.6h377.7c18.6 0 33.8 15.1 33.8 33.8 0 18.7-15.1 27.1-33.8 27.1H320.9c-18.6 0-33.8-8.5-33.8-27.1 0-18.7 15.2-33.8 33.8-33.8zm207.7 317.6h-209c-20 0-36.2-16.2-36.2-36.2 0-20 16.2-29.6 36.2-29.6h208.8c20 0 36.2 9.6 36.2 29.6.2 20-16 36.2-36 36.2zm171.3-123.8h-379c-20 0-36.2-16.2-36.2-36.2s16.2-29.6 36.2-29.6h379c20 0 36.2 9.6 36.2 29.6s-16.2 36.2-36.2 36.2zm143.9-314.6l-216.5 1.6-23.2-17v-235l239.7 250.4z"/>'
  }
})
