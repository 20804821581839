<template>
  <div id="player"></div>
</template>

<script>
import {getMiddlePolyv, getPlayertoken} from "@/api/course/course";

export default {
  props: {
    videoFile: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      player: null,
      vid: null,
      playsafe: null,
      vodPlayerJs: 'https://player.polyv.net/script/player.js'
    }
  },
  methods: {
    // 判断是否加载到保利威的cdn
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement('script');
        myScript.setAttribute('src', this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript)
      } else {
        callback()
      }
    },

    // 获取保利威资源
    async getPeriodPlayToken() {
      const {file: {fromType, id: fileId, fileUrl}, tryWatch, id} = this.videoFile
      this.tryOnSeeingTime = tryWatch * 60
      const {
        data: {
          videoId,
          token
        }
      } = fromType === 'middle' ? await getMiddlePolyv({periodId: id}) : await getPlayertoken({fileId: fileId})
      this.vid = videoId
      this.playsafe = token
      this.loadPlayerScript(this.loadPlayer)
    },
    // 加载播放器
    loadPlayer() {
      const _that = this
      const polyvPlayer = window.polyvPlayer
      this.player = polyvPlayer({
        wrap: '#player',
        width: 640,
        height: 600,
        vid: this.vid,
        speed: false,
        playsafe: this.playsafe,
        ban_history_time: 'on',
        allowFullscreen: false,
      })
      let flat = true
      const video = document.querySelector('.pv-video')
      video.ontimeupdate = function (){
        const {currentTime} = video
        if (currentTime > _that.tryOnSeeingTime && _that.tryOnSeeingTime > 0) {
          _that.player.j2s_seekVideo(_that.tryOnSeeingTime)
          _that.player.j2s_pauseVideo()
          if (flat) {
            flat = false
            _that.$alert('试看已结束，请报名后继续学习！', '提示', {
              confirmButtonText: '确认',
              showClose: false,
              callback: action => {
                _that.$emit('handleApply')
                flat = true
              }
            })
          }
        }
      }
    }
  },
  destroyed() {
    if (this.player) {
      this.player.destroy()
    }
  },
  mounted() {
    this.getPeriodPlayToken()
  }
}
</script>

<style scoped lang="scss">

</style>
