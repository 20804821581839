/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'paper': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M935.371 336.683a29.575 29.575 0 0010.24-20.237 29.538 29.538 0 00-7.112-21.531l-.388-.481c-10.825-12.452-29.66-13.861-42.217-3.163l-404.88 344.194-14.29 48.743 54.853-4.271 403.794-343.254zm48.538-81.25c32.462 37.37 28.505 93.978-8.849 126.467L563.136 732.11a30.048 30.048 0 01-17.15 7.07l-108.081 8.37a30.073 30.073 0 01-25.706-11.05 30.095 30.095 0 01-5.493-27.436l29.094-99.179a30.081 30.081 0 019.391-14.44l411.675-349.997c37.689-32.086 94.18-27.844 126.649 9.505l.394.48zm0 0"/><path pid="1" d="M631.475 1007.653h-.166l-528.959-2.565c-46.375 0-84.237-37.33-84.237-83.212V99.556c0-45.882 37.862-83.208 84.398-83.208h702.633c46.538 0 84.399 37.326 84.399 83.209V158.2c0 18.512-15.006 33.516-33.517 33.516s-33.517-15.004-33.517-33.516V99.557c0-8.92-7.789-16.175-17.365-16.175H102.513c-9.576 0-17.365 7.256-17.365 16.175v822.317c0 8.922 7.79 16.179 17.365 16.179l529.12 2.565c18.51.093 33.444 15.172 33.353 33.681-.088 18.455-15.077 33.354-33.511 33.354z"/><path pid="2" d="M631.472 1007.653a33.404 33.404 0 01-23.66-9.776c-13.11-13.067-13.148-34.286-.08-47.398l210.476-211.205V568.772c0-18.51 15.005-33.517 33.516-33.517s33.517 15.008 33.517 33.517v184.351a33.523 33.523 0 01-9.777 23.661L655.213 997.798a33.416 33.416 0 01-23.741 9.855z"/><path pid="3" d="M631.472 989.67c-18.512 0-33.517-15.007-33.517-33.517v-156.35c0-46.202 37.589-83.79 83.791-83.79h164.53c18.511 0 33.517 15.006 33.517 33.516 0 18.513-15.006 33.516-33.517 33.516h-164.53c-9.24 0-16.758 7.518-16.758 16.759v156.35c0 18.509-15.005 33.516-33.516 33.516zm58.734-698.729H223.581c-18.51 0-33.516-15.003-33.516-33.516 0-18.51 15.005-33.517 33.516-33.517h466.625c18.511 0 33.517 15.007 33.517 33.517 0 18.513-15.006 33.516-33.517 33.516zM491.63 473.257H223.581c-18.51 0-33.516-15.008-33.516-33.517 0-18.513 15.005-33.517 33.516-33.517h268.05c18.51 0 33.516 15.004 33.516 33.517 0 18.51-15.005 33.517-33.517 33.517z"/>'
  }
})
