/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'link': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M353.7 717.404l262.236-443.595 55.455 32.783-262.237 443.595-55.455-32.783z"/><path pid="1" d="M549.1 575.33l-35 56.5a93.44 93.44 0 017.9 108.75l-68 110.47c-26.79 43.49-84.12 57.34-127.8 30.88l-.61-.37-59.7-36.78a93.44 93.44 0 01-30.51-128.41l68.01-110.47c21.59-35 63-50.79 101.11-41.77l35-56.48a157.76 157.76 0 00-190.93 64.46l-68.08 110.47a157.74 157.74 0 0051.57 217l59.7 36.78 1 .62a157.74 157.74 0 00216-52.18l68.06-110.47a157.76 157.76 0 00-27.72-199zM792.18 124.1l-59.7-36.78-1-.62a157.75 157.75 0 00-216 52.18l-68.1 110.48A157.76 157.76 0 00475.8 449l34.9-56.36a93.46 93.46 0 01-8.48-109.49l68.06-110.47c26.79-43.49 84.12-57.34 127.8-30.88l.61.37 59.7 36.78a93.43 93.43 0 0130.51 128.4l-68.05 110.47c-21.41 34.76-62.33 50.51-100.19 42l-35.07 56.58a157.76 157.76 0 00190.1-64.79l68.06-110.47a157.74 157.74 0 00-51.57-217.04z"/>'
  }
})
