import request from '@/utils/request'

// 图片上传
export function uploadPic(data) {
    return request({
        url: '/common/upload',
        method: 'post',
        data: data
    })
}

// 图片上传提交
export function uploadSumit(data) {
    return request({
        url: '/bm/signUp/cred',
        method: 'put',
        data: data
    })
}
// 查询课程信息
export function listCoursepayment(query) {
    return request({
      url: '/bm/signUp/payment',
      method: 'get',
      params: query
    })
  }
  