<template>
  <div>
    <div class="item-wrapper">
      <div class="item-content"
           :class="catalogue.type==='1'?'chapter-wrapper':catalogue.type==='2'?'section-header':'period-wrapper'"
           @click="handlePeriod(catalogue)">
        <div class="item-left">
          <div class="progress"
               :class="{'complete':catalogue.studyLog&&catalogue.studyLog.completeTime,'fiftyPercent':catalogue.studyLog&&catalogue.studyLog.completeTime===null}"
               v-if="catalogue.type==='3'">
            <div class="semicircle" v-if="catalogue.studyLog&&catalogue.studyLog.completeTime===null"></div>
            <i class="el-icon-check" v-if="catalogue.studyLog&&catalogue.studyLog.completeTime"></i>
          </div>
          <svg-icon class="catalogue-icon" v-if="catalogue.type==='1'" name="catalogue"></svg-icon>
          <span class="catalogue-name" :title="catalogue.name ">{{ catalogue.name }}</span>
        </div>
        <div class="item-right">
          <div class="chapter" v-if="catalogue.type==='1'" @click="handleOpen(catalogue)">
            <i class="el-icon-arrow-down" :class="catalogue.open?'up':'down'"></i>
          </div>
          <div class="period" v-if="catalogue.type==='3'">
            <!--判断直播/录播 demand录播 live直播-->
            <div class="demand"
                 v-if="catalogue.file&&(catalogue.file.fileType==='cloud_video'||catalogue.file.fileType==='other'||catalogue.file.fileType==='mooc_video')">
              {{ catalogue.file.videoDuration |conversionTime }}
              <svg-icon class="icon" name="play" v-if="catalogue.periodType==='2'&&catalogue.tryWatch===0"></svg-icon>
              <span class="try-on-seeing" v-if="catalogue.periodType==='2'&&catalogue.tryWatch!==0">试看</span>
            </div>
            <div class="live" v-if="catalogue.file&&catalogue.file.fileType==='cloud_live'">
              <live-state :period="catalogue"></live-state>
              <svg-icon class="icon" name="tv"></svg-icon>
            </div>
            <div class="paper"
                 v-if="(catalogue.periodType&&catalogue.periodType==='5')||(catalogue.periodType&&catalogue.periodType==='6')||(catalogue.periodType&&catalogue.periodType==='8')">
              <svg-icon class="icon"
                        :name="catalogue.periodType==='5'?'paper':catalogue.periodType==='6'?'work':catalogue.periodType==='8'?'link':''"></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :before-close="handleClose">
      <div class="countdown-wrapper">
        <div class="title">直播时间：</div>
        <p>直播将于{{ period.liveStime }}开始，于{{ period.liveEtime }}结束</p>
        <div class="title">倒计时：</div>
        <h4 class="countdown">{{ countdown|conversion }}</h4>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="linkVisible">
      <el-form>
        <el-form-item label="课时名称：">
          {{ link.name }}
        </el-form-item>
        <el-form-item label="链接地址：">
          <el-link :href="link.extraUrl" target="_blank">
            {{ link.extraUrl }}
          </el-link>
        </el-form-item>
        <el-form-item label="课时说明：">
          {{ link.periodDesc }}
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="linkVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleJump">确定跳转</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import liveState from '../live-state/index.vue'
import '@/common/icons/components/play'
import '@/common/icons/components/tv'
import '@/common/icons/components/catalogue'
import '@/common/icons/components/work'
import '@/common/icons/components/paper'
import '@/common/icons/components/link'
import {conversionTime} from "@/common/utils/tools";
// import {getPeriodstudyLog, getPeriodTeacher, getPeriodwatch} from "@/api/video";
// import {startExam} from "@/api/papers";

export default {
  components: {liveState},
  props: {
    catalogue: {
      type: Object,
      default: () => {
      }
    },
    isJoin: {
      type: String,
      default: ''
    },
    needJoin: {
      type: String,
      default: ''
    },
    open: {
      type: [Boolean, undefined],
      default: undefined
    }
  },
  data() {
    return {
      dialogVisible: false,
      countdown: 0,
      period: {},
      linkVisible: false,
      link: {}
    }
  },
  methods: {
    handleJump() {
      this.linkVisible = false
      window.open(this.link.extraUrl)
      if (this.isJoin === '1') {
        getPeriodstudyLog({
          periodId: this.link.id, //课时id
          isComplete: 1,//是否完成，0否1是
        }).then(() => {

        })
      }
    },
    //
    handlePeriod(catalogue) {
      this.$emit('handleCatalogue',catalogue)
      return
      if (catalogue.type === '3') {
        if (catalogue.periodType === '1' || catalogue.periodType === '2') {
          if (catalogue.file.fileType === 'cloud_video' || catalogue.file.fileType === 'other' || catalogue.file.fileType === 'mooc_video') {
            this.playback(this.catalogue)
          } else if (catalogue.file.fileType === 'cloud_live') {
            const date = new Date().getTime()
            const start = new Date(catalogue.liveStime.replace(/-/g, '/'))
            const startTime = start.getTime()
            const end = new Date(catalogue.liveEtime.replace(/-/g, '/')).getTime()
            if (startTime > date) {
              // 直播未开始
              this.jumpLive(false, catalogue)
            } else if (startTime <= date && date <= end) {
              // 正在直播
              this.jumpLive(true, catalogue)
            } else if (date > end) {
              // 直播结束
              this.jumpLive(true, catalogue)

              // if (this.needJoin === '0') {
              //   this.jumpLive(true, catalogue)
              // } else {
              //   this.jumpLive(true, catalogue)
              //   if (catalogue.file.videoDuration === null) {
              //     // 判断是否在直播 是继续进入直播间 不是提示结束
              //     this.$message({type: 'warning', message: '直播已结束，敬请期待回放'})
              //   } else {
              //
              //   }
              // }
            }
          }
        } else if (catalogue.periodType === '5' || catalogue.periodType === '6') {
          console.log('跳转考试/作业')
          if (this.needJoin === '0') {
            // 老师入口 进去预览页面
            this.$router.push({
              path: '/generatingPaper/viewTestpaper',
              query: {
                ids: catalogue.testpaperId,
                lookpaper: 1,
                periodId: catalogue.id
              }
            })
          } else {
            // 学员入口
            if (this.isJoin === '1') {
              this.judgeTestType(catalogue)
            } else {
              // 没有加入
              this.$message({type: 'warning', message: '请先加入课程'})
            }
          }
        } else if (catalogue.periodType === '8') {
          if (this.needJoin === '0') {
            this.link = Object.assign({}, catalogue)
            this.linkVisible = true
          } else {
            // 学员入口
            if (this.isJoin === '1') {
              this.link = Object.assign({}, catalogue)
              this.getPeriodstudyLog(catalogue.id)
              this.linkVisible = true
            } else {
              // 没有加入
              this.$message({type: 'warning', message: '请先加入课程'})
            }
          }
        }
      }
    },
    judgeTestType(period) {
      switch (period.status) {
        case null:
          this.startWork(period)
          break
        case 'DOING':
          this.jumpPaper(period.testpaperResultId)
          break
        case 'REVIEWING':
          this.$message({type: 'warning', message: '试卷正在批阅，请耐心等待批阅结果'})
          break
        case 'FINISHED':
          this.$router.push({
            path: '/generatingPaper/readTestPaper',
            query: {
              ids: period.testpaperResultId,
              number: 4
            }
          })
          break
      }
    },
    startWork(period) {
      const type = period.periodType
      if (type === '5') {
        // 考试
        this.examTips(period)
      } else {
        // 作业
        this.startExam(period)
      }
    },
    // 开始答卷
    startExam(period, callback) {
      startExam({id: period.id}).then((res) => {
        if (res.code === 200) {
          this.getPeriodstudyLog(period.id)
          this.jumpPaper(res.data.testpaperResultId)
          if (callback) setTimeout(() => {
            callback()
          }, 3000)
        }
      })
    },
    getPeriodstudyLog(periodId) {
      getPeriodstudyLog({
        periodId: periodId, //课时id
        isComplete: 0,//是否完成，0否1是
      }).then(() => {

      })
    },
    // 跳转试卷页面
    jumpPaper(id) {
      this.$router.push({
        path: 'generatingPaper/answerTestPaper',
        query: {
          id: id,
          // periodId:period.id
        }
      })
    },
    // 考试确认弹窗
    examTips(period) {
      const h = this.$createElement
      this.$msgbox({
        title: '考试说明',
        message: h('p', null, [
          h('span', null, '本次考试共'),
          h('span', {style: 'color:#1163EB'}, period.itemCount + '题'),
          h('span', null, '，共'),
          h('span', {style: 'color:#1163EB'}, period.score + '分'),
          h('span', null, period.limitedTime > 0 ? '，请在' : ''),
          h('span', {style: 'color:#1163EB'}, period.limitedTime > 0 ? period.limitedTime + '分钟' : ''),
          h('span', null, period.limitedTime > 0 ? '内完成做答' : ''),
        ]),
        showCancelButton: true,
        confirmButtonText: '开始考试',
        cancelButtonText: '返回',
        beforeClose: (action, instance, done) => {
          console.log(action)
          // 开始考试
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '执行中...';
            setTimeout(() => {
              setTimeout(() => {
                function requestDone() {
                  instance.confirmButtonLoading = false
                  done()
                }

                this.startExam(period, requestDone())
              }, 300)
            }, 3000)
          } else {
            // 取消答题 返回
            done();
          }
        }
      }).then(action => {
        console.warn(action)
      })
    },
    handleOpen() {
      this.$emit('handleOpen')
    },
    handleClose() {
      window.clearInterval(this.timer)
      this.dialogVisible = false
    },
    // 跳转直播
    jumpLive(state, period) {
      if (this.needJoin === '0') {
        const date = new Date().getTime()
        const startTime = new Date(period.liveStime.replace(/-/g, '/')).getTime() - 60 * 60 * 2 * 1000
        const endTime = new Date(period.liveEtime.replace(/-/g, '/')).getTime() + 60 * 60 * 2 * 1000
        if (startTime < date && date < endTime) {
          getPeriodTeacher({
            periodId: period.id
          }).then(res => {
            window.open(res.data.url, '_blank');
          })
        } else {
          if (date > endTime) {
            if (period.file.videoDuration) {
              // 判断是否有回放
              if (period.file.playbackList.length > 0) {
                this.playback(period)
              } else {
                getPeriodwatch({
                  periodId: period.id,
                  channelId: period.file.fileUrl
                }).then((res) => {
                  window.open(res.data, '_blank');
                })
              }
            } else {
              this.$message({type: 'warning', message: '不在直播允许时间范围内，不能进入直播间'})
            }
          } else {
            this.$message({type: 'warning', message: '不在直播允许时间范围内，不能进入直播间'})
          }
        }
      } else {
        if (this.isJoin === '1') {
          if (state) {
            // 判断是否有回放
            if (period.file.playbackList.length > 0) {
              this.playback(period)
            } else {
              getPeriodwatch({
                periodId: period.id,
                channelId: period.file.fileUrl
              }).then((res) => {
                window.open(res.data, '_blank');
              })
            }
          } else {
            const date = new Date().getTime()
            const start = new Date(period.liveStime.replace(/-/g, '/')).getTime()
            const preview = start - 60 * 60 * 1000 * 2
            if (preview > date) {
              this.timer = setInterval(() => {
                const date = new Date().getTime()
                this.countdown = start - date
                if (this.countdown === 0) {
                  window.clearInterval(this.timer)
                }
              }, 1000)
              setTimeout(() => {
                this.period = period
                this.dialogVisible = true
              }, 1000)
            } else {
              getPeriodwatch({
                periodId: period.id,
                channelId: period.file.fileUrl
              }).then((res) => {
                window.open(res.data, '_blank');
              })
            }
          }
        } else {
          this.$message({type: 'warning', message: '请先加入课程'})
        }
      }
    },
    // 跳转录播
    playback(period) {
      if (this.needJoin === '0') {
        let routeUrl = this.$router.resolve({
          path: "/courseVideo",
          query: {classhour: period.id, isJoin: this.isJoin, needJoin: this.needJoin,}
        });
        window.open(routeUrl.href, '_blank');
      } else {
        if (this.isJoin === '1') {
          let routeUrl = this.$router.resolve({
            path: "/courseVideo",
            query: {classhour: period.id, isJoin: this.isJoin, needJoin: this.needJoin,}
          });
          window.open(routeUrl.href, '_blank');
        } else {
          this.$message({type: 'warning', message: '请先加入课程'})
        }
      }
    }
  },
  filters: {
    conversionTime(time) {
      return conversionTime(time)
    },
    conversion(time) {
      const day = Math.floor(time / 24 / 60 / 60 / 1000)
      const hour = Math.floor((time - day * 24 * 60 * 60 * 1000) / 60 / 60 / 1000)
      const minute = Math.floor((time - day * 24 * 60 * 60 * 1000 - hour * 60 * 60 * 1000) / 60 / 1000)
      const second = Math.floor((time - day * 24 * 60 * 60 * 1000 - hour * 60 * 60 * 1000 - minute * 60 * 1000) / 1000)
      return day + '天' + hour + '时' + minute + '分' + second + '秒'
    }
  },
}
</script>

<style scoped lang="scss">
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-wrapper {

  .item-content {
    @include center;
    justify-content: space-between;
    height: 56px;
    padding: 0 20px;

    &.chapter-wrapper, &.section-header, &.period-wrapper {
      margin-bottom: 12px;

      .item-left {
        @include center;
        font-size: 16px;
        color: #000000;

        .catalogue-icon {
          width: 18px !important;
          height: 18px !important;
          fill: #B1BACC;
          margin-right: 10px;
        }

        .catalogue-name {
          width: 400px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .progress {
          @include center;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          border: 1px solid #1163EB;
          overflow: hidden;
          margin-right: 12px;

          &.fiftyPercent {
            align-items: flex-end;

            .semicircle {
              width: 18px;
              height: 9px;
              background: #1163EB;
              border-radius: 0 0 18px 18px;
            }
          }

          &.complete {
            background: #1163EB;

            .el-icon-check {
              font-size: 12px;
              color: #FFFFFF;
            }
          }
        }
      }

      .item-right {
        @include center;

        .chapter {
          cursor: pointer;

          .el-icon-arrow-down {
            transition: all .3s ease;

            &.up {
              transform: rotate(-90deg);
            }

            &.down {

            }
          }
        }

        .period {
          .demand, .live {
            @include center;
            font-size: 14px;
            color: #656565;

            .icon {
              margin-left: 14px;
            }
            .try-on-seeing{
              font-size: 14px;
              color: #1d70ea;
              margin-left: 14px;
            }
          }
        }
      }
    }

    &.chapter-wrapper {
      background: #fafafa;
      color: rgba(0, 0, 0, .32);
    }

    &.section-header {
      background: #fafafa;
      color: rgba(0, 0, 0, 1);
    }

    &.period-wrapper {
      background: #fafafa;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      cursor: pointer;

      &:hover {
        background: rgba(17, 99, 235, 0.2);
      }
    }
  }


}

.countdown-wrapper {
  @include center;
  flex-direction: column;

  .title {
    font-size: 20px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }

  .countdown {
    color: #F15A25;
  }
}
</style>
