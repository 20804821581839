import request from "@/utils/request";

/**
 * 获取课程分类列表
 * GET /course/pub/category/list
 * */
export function getCourseCategory(params) {
    return request({
        url: '/course/pub/category/list',
        method: 'get',
        params: params
    })
}

/**
 * 获取热门或者最新课程
 * GET /course/api/list
 * sortType: hots 热门 isNews最新
 * */
export function getCourseList(params) {
    return request({
        url: '/course/front/list',
        method: 'get',
        params: params
    })
}

/**
 * 获取章节课时树信息
 * */
export function getCoursePeriodTree(params) {
    return request({
        url: '/course/front/period/list/tree',
        method: 'get',
        params: params
    })
}

// 获取moc的课程签名
export function getVideoSign(params) {
    return request({
        url: '/course/front/period/moocVideo/sign',
        method: 'get',
        params: params
    })
}
// 课程中台保利威
export function getMiddlePolyv(params) {
    return request({
        url: '/course/front/period/middle/polyv/play',
        method: 'get',
        params: params
    })
}

// 课程中台自定义视频
export function getMiddleCustom(params){
    return  request({
        url:'/course/front/period/middle/play',
        method:'get',
        params:params
    })
}

// 获取moc的课程签名
export function getMiddleMooc(params) {
    return request({
        url: '/course/front/period/middle/mooc/play',
        method: 'get',
        params: params
    })
}

// 判断该播放课时是否有效
export function getPeriodExist(params) {
    return request({
        url: `/course/front/period/video/exist`,
        method: 'get',
        params: params
    })
}

// 点播视频观看token
export function getPlayertoken(params) {
    return request({
        url: '/course/front/period/player/token',
        method: 'get',
        params: params
    })
}
