<template>
  <div class="state-wrapper" :class="live?'active':''" @click="handlePeriodState">
    {{ liveState }}
    <div class="live-end" v-if="liveEnd">
      <span class="playback">回放录像</span>
      <span>直播结束</span>
    </div>
  </div>
</template>

<script>
import {formatDate} from "@/utils/date";

export default {
  props: {
    period: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      liveState: '',
      liveEnd: false,
      live: false
    }
  },
  methods: {
    // 判断当前直播状态
    handlePeriodState() {
      // 正在直播
      if (this.liveState === '直播结束') {
        return
      } else {
        this.$emit('jumpLive', this.live, this.period)
      }
    },
    // 查看回看
    playback() {
      this.$emit('jumpLive', this.live, this.period)
    },
    // 获取课时状态
    getState() {
      const date = new Date().getTime()
      const start = new Date(this.period.liveStime.replace(/-/g, '/'))
      const startTime = start.getTime()
      const end = new Date(this.period.liveEtime.replace(/-/g, '/')).getTime()
      let text = ''
      if (startTime > date) {
        let month = start.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        let day = start.getDate();
        day = day < 10 ? "0" + day : day;
        let time = formatDate(start, "hh:mm:ss");
        text = month + '-' + day + ' ' + time + '开始'
      } else if (startTime <= date && date <= end) {
        text = '正在直播'
        this.live = true
      } else if (date > end) {
        if (this.period.file.videoDuration === null) {
          text = '直播结束'
        } else {
          text = ''
          this.liveEnd = true
        }
      }
      this.liveState = text
    }
  },
  mounted() {
    this.getState()
  },
  watch: {
    period: {
      handler(newVal) {
        this.getState()
      },
      immediate: true
    },
  }
}
</script>

<style scoped lang="scss">
.state-wrapper {
  display: flex;
  align-items: center;
  color: #656565;
  font-size: 14px;

  &.active {
    color: #1163EB;
  }

  .live-end {
    display: flex;
    align-items: center;

    span {
      color: #656565;
    }

    .playback {
      color: #1163EB;
      padding: 0 9px;
      margin-right: 9px;
      border-right: 1px solid #595959;
    }
  }
}
</style>
