<template>
  <div class="video-js vjs-default-skin vjs-big-play-centered"
       controlsList="nodownload" disablePictureInPicture ref="video" style="width: 640px;height: 600px"></div>
</template>

<script>
import {getMiddleMooc, getVideoSign} from "@/api/course/course";

export default {
  props: {
    videoFile: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      player: null,
      tryOnSeeingTime: 0
    }
  },
  methods: {
    // 视频初始化
    init(params) {
      this.player = window.EduPlayer.create({
        parent: this.$refs.video,
        notAllowFullScreen:true,
        videoData: {
          videoId: params.videoId,
          signature: params.signature,
        }
      })
      let flat = true
      this.player.$on('onTimeupdate', (data) => {
        const {currentTime} = data
        if (currentTime > this.tryOnSeeingTime && this.tryOnSeeingTime > 0) {
          this.player.seek(this.tryOnSeeingTime)
          this.player.pause()
          if (flat) {
            flat = false
            this.$alert('试看已结束，请报名后继续学习！', '提示', {
              confirmButtonText: '确认',
              showClose: false,
              callback: action => {
                this.$emit('handleApply')
                flat = true
              }
            })
          }
        }
      })
    },
    // 获取视频签名
    async getMoocVideoSign() {
      const {file: {fromType, fileUrl}, tryWatch,id} = this.videoFile
      this.tryOnSeeingTime = tryWatch * 60
      const {data} = fromType === 'middle' ? await getMiddleMooc({periodId: id}) : await getVideoSign({videoId: fileUrl})
      this.init(data)
    }
  },
  destroyed() {
    this.player.destroy()
    this.player.$off('onTimeupdate')
  },
  mounted() {
    this.getMoocVideoSign()
  }
}
</script>

<style>
.video-js .vjs-picture-in-picture-control {
  display: none;
}

.u-edu-h5player-controlwrap .m-controlbar .controlbar_btn {
  height: initial !important;
}

.u-edu-h5player-controlwrap .m-controlbar .controlbar_btn.j-ratebtn {
  display: none !important;
}
.j-fullscreen{
  display: none!important;
}
</style>
