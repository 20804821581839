/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'work': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M830.7 98.61c-.74 0-1.49-.06-2.23-.08l.07.07H282.85A123.88 123.88 0 00158.46 222v535.33H93.85a30.48 30.48 0 00-30 30v2.29a138.58 138.58 0 00138.22 138.14H665a160.23 160.23 0 00161.25-159.22V269h103.56a30.48 30.48 0 0030-30A134.89 134.89 0 00830.7 98.61zm-628.59 767a74.89 74.89 0 01-71.39-48.37h407.63a117.8 117.8 0 0023 48.37zm564.26-99a103 103 0 01-98.87 99 86.18 86.18 0 01-76-80.63 30.47 30.47 0 00-30-30H220.67V223a62.48 62.48 0 0162.73-62.21h482.97zm57.55-559.68v-48.41h4.92A63.69 63.69 0 01891 206.86z"/><path pid="1" d="M515.19 527H317.13a34.55 34.55 0 000 69.09h198.06a34.55 34.55 0 100-69.09zM315 409.51h347.69a34.55 34.55 0 100-69.09H315a34.55 34.55 0 100 69.09z"/>'
  }
})
