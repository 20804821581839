/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'play': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M512.717 942.234c-237.159 0-430.08-192.922-430.08-430.08s192.921-430.08 430.08-430.08 430.08 192.921 430.08 430.08-192.922 430.08-430.08 430.08zm0-798.72c-203.264 0-368.64 165.376-368.64 368.64s165.376 368.64 368.64 368.64 368.64-165.376 368.64-368.64-165.376-368.64-368.64-368.64z" _fill="#2c2c2c"/><path pid="1" d="M452.045 690.688c-12.595 0-25.19-3.328-36.66-9.933-22.937-13.26-36.659-37.017-36.659-63.488V407.091c0-26.521 13.722-50.227 36.66-63.488 22.988-13.26 50.38-13.26 73.318 0L670.72 448.666c22.938 13.26 36.66 36.966 36.66 63.488 0 26.521-13.722 50.227-36.66 63.488L488.704 680.704c-11.469 6.656-24.064 9.984-36.66 9.984zm.051-295.578c-2.56 0-4.66.922-5.99 1.639-2.202 1.28-5.94 4.3-5.94 10.291v210.176c0 5.99 3.738 9.011 5.94 10.291 2.201 1.28 6.707 3.021 11.878 0L640 522.445c5.171-2.97 5.94-7.731 5.94-10.291s-.769-7.322-5.94-10.292L457.984 396.8c-2.1-1.229-4.096-1.69-5.888-1.69z" _fill="#2c2c2c"/>'
  }
})
